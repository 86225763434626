import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faAt, faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => (
    <>
        <header><h1>Contact Us</h1></header>
        <dl>
            <div>
                <dt><FontAwesomeIcon icon={faPhone} /> Phone</dt>
                <dd><a href="tel:406-361-6042">406-361-6042</a></dd>
            </div>
            <div>
                <dt><FontAwesomeIcon icon={faAt} /> Email</dt>
                <dd><a href="mailto:info@orchardhomesditch.org">info@orchardhomesditch.org</a></dd>
            </div>
            <div>
                <dt><FontAwesomeIcon icon={faEnvelope} /> Mail</dt>
                <dd><address>
                    P. O. Box 5402<br />
                    Missoula, MT 59806
                </address></dd>
            </div>
        </dl>
    </>
);
export default ContactUs;