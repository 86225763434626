import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

import * as styles from './SocialFollow.module.css';

const SocialFollow = () => (
    <div class={styles.social_follow}>
        <div class={styles.social_icons}>
            <div>
                &copy; {new Date().getFullYear()} Orchard Homes Ditch Company
            </div>
            <div>
                <a href="https://facebook.com/orchardhomesditch" title="follow us on facebook"> Follow us on <FontAwesomeIcon icon={faFacebook} /></a>

            </div>
        </div>
    </div>
);


export default SocialFollow;