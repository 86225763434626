import React from 'react';

import MailingListForm from '../components/MailingListForm';

import SilversLagoonImage from "../images/ditch_at_silvers_lagoon.jpeg";

import "./index.css";
import SocialFollow from '../components/SocialFollow';
import AboutUs from '../components/AboutUs';
import ContactUs from '../components/ContactUs/ContactUs';
import ServiceArea from '../components/ServiceArea/ServiceArea';
import SiteHeader from '../components/SiteHeader';



// markup
const IndexPage = () => {
    const maillistFormPostUrl = 'https://orchardhomesditch.us12.list-manage.com/subscribe/post?u=56776a553bd6a10f72d39bbb2&amp;id=26272f03a3';

    return (
        <>
            <SiteHeader />
            <main>
                <section id="ServiceArea">
                    <ServiceArea />
                </section>
                <section id="AboutUs" style={{ backgroundImage: `url(${SilversLagoonImage})`, backgroundSize: "cover" }}>
                    <AboutUs />
                </section>
                <section id="Contact">
                    <ContactUs />
                </section>
                <section id="Social">
                    <header><h1>Follow us</h1></header>
                    <div>
                        <MailingListForm postUrl={maillistFormPostUrl} />
                    </div>

                </section>
                <footer>
                    <SocialFollow />
                </footer>
            </main>
        </>
    );
};

export default IndexPage;
