import React from 'react';

import * as styles from './AboutUs.module.css';

const AboutUs = () => (
    <div class={styles.aboutus}>
        <div>

        </div>
        <div>
            <header><h1>About Us</h1></header>
            <p>Orchard Homes Ditch Company was established in 1906 and owns and maintains ditches from our main intake near Brennan's Wave on the Clark Fork River west to just past Short St. The ditch is one of a series of irrigation ditches throughout the Missoula valley with the purpose to provide irrigation water. The ditch irrigates commercial nurseries, the River Road Community Garden, Silver's Lagoon, and many private yards and gardens.</p>

        </div>
    </div>
);

export default AboutUs;
