import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';

import IntakeHeadGateImage from "../../images/intake-headgate.jpeg";

import * as styles from './SiteHeader.module.css';

const SiteHeader = () => {
    const data = useStaticQuery(graphql`
        query {
        site {
            siteMetadata {
            title
            tagline
            }
        }
        }
    `);


    const { title, tagline } = data.site.siteMetadata;

    return <header className={styles.siteheader}>
        <Helmet>
            <title>{title} :: {tagline}</title>
        </Helmet>
        <div class="container mx-auto px-6 py-3 ">
            <div class="md:flex md:items-center md:justify-between">
                <div class="flex justify-between items-center">
                    <div class="text-xl font-semibold text-gray-700">
                        <Link to='/' className="text-gray-800 text-xl font-bold hover:text-gray-700 md:text-2xl">{title}</Link>
                    </div>

                    {/* Mobile menu button */}
                    <div class="flex md:hidden">
                        <button type="button" class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="toggle menu">
                            <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
                                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>

                {/* Mobile Menu open: "block", Menu closed: "hidden" */}
                <div class="hidden -mx-4 md:flex md:items-center">
                    <Link to="/#ServiceArea" className="block mx-4 mt-2 md:mt-0 text-sm text-gray-700 capitalize hover:text-blue-600">Service Area</Link>
                    <Link to="#AboutUs" className="block mx-4 mt-2 md:mt-0 text-sm text-gray-700 capitalize hover:text-blue-600">About Us</Link>
                    <Link to="#Contact" className="block mx-4 mt-2 md:mt-0 text-sm text-gray-700 capitalize hover:text-blue-600">Contact</Link>
                </div>
            </div>
        </div>
        <div className="w-full bg-cover bg-center" style={{ height: "32rem", backgroundImage: `url(${IntakeHeadGateImage})` }}>
            <div class="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
                <div class="text-center">
                    <h1 className="text-white text-2xl font-semibold uppercase md:text-3xl">{tagline}</h1>
                </div>
            </div>
        </div>
    </header>;
};

export default SiteHeader;