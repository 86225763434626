import React from 'react';

import MailchimpSubscribe from "react-mailchimp-subscribe";

import * as styles from './MailingListForm.module.css';


// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let emailInput, firstNameInput, lastNameInput, addressInput;

    const submit = () => {
        emailInput &&
            emailInput.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: emailInput.value,
                FNAME: firstNameInput.value,
                LNAME: lastNameInput.value,
                ADDRESS: addressInput.value
            });

    };

    return (
        <div className={styles.center}>
            <div className={styles.mailing_list_form}>
                {status === "sending" && <div class={styles.message}>sending...</div>}
                {status === "error" && (
                    <div
                        class={`${styles.message} ${styles.error}`}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        class={styles.message}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {!['success', 'sending'].includes(status) && (<>
                    <span className={styles.formgroup}>
                        <label htmlFor="EmailInput">Email </label>
                        <input
                            id="EmailInput"
                            ref={node => (emailInput = node)}
                            type="email"
                        />
                        <small>* required</small>
                    </span>
                    <span className={styles.formgroup}>
                        <label htmlFor="FirstNameInput">First Name</label>
                        <input
                            ref={node => (firstNameInput = node)}
                            type="text"
                        />
                    </span>
                    <span className={styles.formgroup}>
                        <label htmlFor="LastNameInput">Last Name</label>
                        <input
                            ref={node => (lastNameInput = node)}
                            type="text"
                        />
                    </span>
                    <span className={styles.formgroup}>
                        <label htmlFor="AddressInput">Street Address </label>
                        <input
                            type="text"
                            id="AddressInput"
                            ref={node => (addressInput = node)}
                        />
                        <small>* house number and street</small>
                    </span>
                    <span className={styles.formgroup}><button onClick={submit}>Subscribe</button></span>
                </>
                )}

            </div>
        </div>
    );
};

const MailingListForm = ({ postUrl }) => (
    <div className={styles.MailingList}>
        <h2>Join our mailing list</h2>
        <MailchimpSubscribe url={postUrl} render={({ subscribe, status, message }) => (
            <CustomForm
                status={status}
                message={message}
                onValidated={subscribe}
            />
        )} />
    </div>
);

export default MailingListForm;;