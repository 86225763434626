import BoundariesGeoJSON from "./BoundariesGeoJSON";
import OrchardHomesDitchGeoJson from "./OrchardHomesDitchGeoJson";

const MapboxStyle = {
    "version": 8,
    "name": "qgis2web export",
    "pitch": 0,
    "light": {
        "intensity": 0.2,
    },
    "sources": {
        "OpenStreetMap_0": {
            "type": "raster",
            "tiles": ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
            "tileSize": 256,
        },
        "orchard_homes_irr_1": {
            "type": "geojson",
            "data": OrchardHomesDitchGeoJson,
        },
        "Boundaries_2": {
            "type": "geojson",
            "data": BoundariesGeoJSON,
        }
    },
    "sprite": "",
    "glyphs": "https://glfonts.lukasmartinelli.ch/fonts/{fontstack}/{range}.pbf",
    "layers": [
        {
            "id": "background",
            "type": "background",
            "layout": {},
            "paint": {
                "background-color": "#ffffff"
            }
        },
        {
            "id": "lyr_OpenStreetMap_0_0",
            "type": "raster",
            "source": "OpenStreetMap_0"
        },
        {
            "id": "lyr_Boundaries_2_0",
            "type": "line",
            "source": "Boundaries_2",
            "layout": {},
            "paint": { 'line-width': 3.0, 'line-opacity': 1.0, 'line-color': '#ff0004' }
        },
        {
            "id": "lyr_orchard_homes_irr_1_0",
            "type": "line",
            "source": "orchard_homes_irr_1",
            "layout": {},
            "paint": {
                'line-width': 3.0,
                'line-opacity': 1.0,
                'line-color': '#006aff'
            }
        },
        {
            "id": "lyr_Boundaries_2_1",
            "type": "fill",
            "source": "Boundaries_2",
            "layout": {},
            "paint": {
                "fill-color": '#ff0004',
                'fill-opacity': 0.2,
            }
        },
    ],
};

export default MapboxStyle;