import React, { useRef, useEffect } from 'react';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import * as styles from './ServiceArea.module.css';

import MapboxStyle from './MapboxStyle';
import ServiceAreaImage from './service-area-background.png';

mapboxgl.accessToken = "pk.eyJ1Ijoib3JjaGFyZGhvbWVzZGl0Y2giLCJhIjoiY2w1dnN4bzJlMDMwejNqbW02emIxZjJ6cyJ9.hsO6fiOKDSxwONCx4gOKpA";

const ServiceArea = () => {
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: MapboxStyle,
            center: [-114.03164331496497, 46.873767102016835],
            zoom: 13
        });

        map.current.addControl(new mapboxgl.NavigationControl());

        map.current.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                autocomplete: false,
                bbox: [-114.136470, 46.812007, -113.896155, 46.932167]
            }),
            'top-left'
        );

        map.current.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        }));
    });

    return (
        <div class={styles.servicearea} style={{ backgroundImage: `url(${ServiceAreaImage})`, backgroundSize: "cover" }}>
            <div>
                <header><h1>Service Area</h1></header>
                <p>The Orchard Homes ditch provides irrigation water to users generally west of California Street, North of 3rd Street, South of the Clark Fork River and east of Hiberta Street. Outside of this area you're likely part of the Missoula Irrigation District.</p>
                <p>You can you use the map to search for your address. If you live within the red shaded area you're are part of the Orchard Homes Ditch irrigation district. If you're outside the red shaded area then you're likely in the <a href="https://www.missoulacounty.us/community/special-districts" title="Missoual Irrigation District information">Missoula Irrigation District</a> and need to contact them with any questions.</p>
            </div>
            <div className={styles.serviceAreaMap}>
                <div ref={mapContainer} className={styles.mapContainer} />
            </div>
        </div>
    );
};

export default ServiceArea;